import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import "../CSS/AdminPage.css";
import NavigationBar from "./Navbar";
import Badge from "react-bootstrap/Badge";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";

const ROLE_NAMES = {
  root: "System Root User",
  admin: "Organization Admin",
  viewer: "Viewer",
};

function AdminPage({ setToken }) {
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState({});
  const [canEditUsers, setCanEditUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const [showUsernameAlert, setShowUsernameAlert] = useState(false);

  const [locations, setLocations] = useState([]);
  const [status, setStatus] = useState("new");
  const [id, setId] = useState(null);
  const [editingUserInfo, setEditingUserInfo] = useState({
    username: "",
    password: "",
    password2: "",
    name: "",
    locations: [],
    role: "viewer",
  });

  let history = useHistory();

  // Set axios header
  const axiosConfig = useMemo(() => {
    return {
      headers: {
        Authorization: ["Bearer", token].join(" "),
      },
    };
  }, [token]);

  const fetchLocations = () => {
    if (token) {
      axios
        .get("/api/location/all", axiosConfig)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
        });
    }
  };

  const fetchEditingUserInfo = (id) => {
    if (token) {
      axios
        .get(`/api/user/edit/${id}`, axiosConfig)
        .then((res) => {
          let info = res.data;
          setEditingUserInfo({
            username: info.username,
            name: info.name,
            role: info.role,
            locations: info.locations.map((location) => ({
              value: location._id,
              label: `${location.feed.type.toUpperCase()} / ${
                location.name
              } / ${location.city}, ${location.state}`,
            })),
          });
          setStatus("edit");
          setShowEditModal(true);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            history.push("/");
            localStorage.removeItem("token");
          }
        });
    }
  };

  const fetchUserInfo = () => {
    if (token) {
      axios
        .get("/api/user/auth", axiosConfig)
        .then((res) => {
          setUserInfo(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            history.push("/");
            localStorage.removeItem("token");
          }
        });
    } else {
      history.push("/");
    }
  };

  const fetchCanEditUsers = () => {
    if (token) {
      axios
        .get("/api/user/list", axiosConfig)
        .then((res) => {
          setCanEditUsers(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
        });
    }
  };

  const handleAdd = () => {
    setStatus("new");
    setEditingUserInfo({
      username: "",
      password: "",
      password2: "",
      name: "",
      locations: [],
      role: "viewer",
    });
    setShowEditModal(true);
  };

  const handleEdit = (id) => {
    if (id === "current") {
      setId(userInfo.id);
      fetchEditingUserInfo(userInfo.id);
    } else {
      setId(id);
      fetchEditingUserInfo(id);
    }
  };

  const handleDelete = (id) => {
    setId(id);
    setShowDeleteModal(true);
  };

  const executeDelete = useCallback(() => {
    // execute delete
    axios
      .delete(`/api/user/${id}`, axiosConfig)
      .then((res) => {
        setShowDeleteModal(false);
        fetchCanEditUsers();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // Unauthorized
          console.error(err);
          localStorage.removeItem("token");
        }
      });
  }, [id]);

  const executeCreateOrEdit = useCallback(() => {
    if (status === "new") {
      // Creating a new account
      if (editingUserInfo.password !== editingUserInfo.password2) {
        setShowPasswordAlert(true);
        setTimeout(() => {
          setShowPasswordAlert(false);
        }, 2000);
      } else {
        let payload = {
          username: editingUserInfo.username,
          password: editingUserInfo.password,
          name: editingUserInfo.name,
          role: editingUserInfo.role,
          locations: editingUserInfo.locations.map(
            (location) => location.value
          ),
        };

        if (token) {
          axios
            .post("/api/user/create", payload, axiosConfig)
            .then((res) => {
              setShowEditModal(false);
              fetchCanEditUsers();
            })
            .catch((err) => {
              if (err.response.status === 401) {
                // Unauthorized
                console.error(err);
                localStorage.removeItem("token");
              } else if (err.response.status === 400) {
                setShowUsernameAlert(true);
                setTimeout(() => {
                  setShowUsernameAlert(false);
                }, 2000);
              }
            });
        }
      }
    } else if (status === "edit") {
      // Editing existing account
      if (editingUserInfo.password !== editingUserInfo.password2) {
        setShowPasswordAlert(true);
        setTimeout(() => {
          setShowPasswordAlert(false);
        }, 2000);
      } else {
        let payload = {
          name: editingUserInfo.name,
          locations: editingUserInfo.locations.map(
            (location) => location.value
          ),
        };
        if (editingUserInfo.password !== "") {
          payload.password = editingUserInfo.password;
        }

        if (token) {
          axios
            .post(`/api/user/edit/${id}`, payload, axiosConfig)
            .then((res) => {
              setShowEditModal(false);
              fetchCanEditUsers();
              fetchUserInfo();
            })
            .catch((err) => {
              if (err.response.status === 401) {
                // Unauthorized
                console.error(err);
                localStorage.removeItem("token");
              }
            });
        }
      }
    }
  }, [status, editingUserInfo, id]);

  useEffect(() => {
    fetchCanEditUsers();
    fetchLocations();
    fetchUserInfo();
  }, [token]);

  return (
    <div className="single-page">
      <NavigationBar userInfo={userInfo} setToken={setToken} />
      <div className="box">
        <UserTable
          canEditUsers={canEditUsers}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </div>

      {/* Delete Modal Goes here */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If Organization Admin is deleted, all of its viewer accounts will also
          be deleted.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={executeDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit Modal Goes here */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {status === "new" ? "Create New User" : "Edit User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showPasswordAlert && (
            <Alert variant="danger">
              Passwords do not match. Please check again.
            </Alert>
          )}
          {showUsernameAlert && (
            <Alert variant="danger">Username exists. Please try again.</Alert>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Username"
                value={editingUserInfo.username}
                onChange={(e) => {
                  setEditingUserInfo({
                    ...editingUserInfo,
                    username: e.target.value,
                  });
                }}
                disabled={status === "edit"}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={editingUserInfo.name}
                onChange={(e) => {
                  setEditingUserInfo({
                    ...editingUserInfo,
                    name: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                {status === "new" ? "Password" : "New Password"}
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={editingUserInfo.password}
                onChange={(e) => {
                  setEditingUserInfo({
                    ...editingUserInfo,
                    password: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword2">
              <Form.Label>
                {status === "new" ? "Confirm Password" : "Confirm New Password"}
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={editingUserInfo.password2}
                onChange={(e) => {
                  setEditingUserInfo({
                    ...editingUserInfo,
                    password2: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                custom
                value={editingUserInfo.role}
                onChange={(e) =>
                  setEditingUserInfo({
                    ...editingUserInfo,
                    role: e.target.value,
                  })
                }
                disabled={status === "edit"}
              >
                <option value="viewer">{ROLE_NAMES["viewer"]}</option>
                {userInfo.role === "root" && (
                  <option value="admin">{ROLE_NAMES["admin"]}</option>
                )}
                {userInfo.role === "root" && (
                  <option value="root">{ROLE_NAMES["root"]}</option>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Visible Locations</Form.Label>
              {editingUserInfo.role !== "root" && (
                <Select
                  isMulti={true}
                  closeMenuOnSelect={false}
                  defaultValue={editingUserInfo.locations}
                  onChange={(options) => {
                    setEditingUserInfo({
                      ...editingUserInfo,
                      locations: options,
                    });
                  }}
                  options={locations.map((location) => ({
                    value: location._id,
                    label: `${location.feed.type.toUpperCase()} / ${
                      location.name
                    } / ${location.city}, ${location.state}`,
                  }))}
                />
              )}
              {editingUserInfo.role === "root" && (
                <Form.Text style={{fontSize: '1rem'}} muted>All locations</Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={executeCreateOrEdit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function UserTable({ canEditUsers, handleAdd, handleEdit, handleDelete }) {
  return (
    <div className="users">
      <div className="wrap">
        <div className="header-bar">
          <h1>Users</h1>
          <Button
            variant="outline-success"
            className="ml-2"
            onClick={handleAdd}
          >
            Create New User
          </Button>
          <Button
            variant="outline-info"
            className="ml-2"
            onClick={() => handleEdit("current")}
          >
            Edit Current User
          </Button>
        </div>
        {canEditUsers.length === 0 && <p>No users.</p>}
        {canEditUsers.length > 0 && (
          <Table className="user-list" bordered responsive>
            <thead className="table-header">
              <tr>
                <th>Username</th>
                <th>Role</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {canEditUsers.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td className="role">
                    <Badge
                      variant={user.role === "admin" ? "success" : "secondary"}
                    >
                      {ROLE_NAMES[user.role]}
                    </Badge>
                  </td>
                  <td>{user.name}</td>
                  <td>
                    <ButtonGroup>
                      <Button
                        variant="warning"
                        onClick={() => handleEdit(user._id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(user._id)}
                      >
                        Delete
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
