import React, { useRef, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import "react-datetime/css/react-datetime.css";
import "../CSS/QueryPage.css";
import NavigationBar from "./Navbar";

import Datetime from "react-datetime";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";

const URL = "/api/location/all";
const DETECTION_TYPES = ["car", "truck", "bus", "person", "bicycle"];
const WEATHER_TYPES = [
  "Thunderstorm",
  "Drizzle",
  "Rain",
  "Snow",
  "Mist",
  "Smoke",
  "Haze",
  "Dust",
  "Fog",
  "Sand",
  "Ash",
  "Squall",
  "Tornado",
  "Clear",
  "Clouds",
];

const DETECTION_OPTIONS = DETECTION_TYPES.map((type) => ({
  value: type,
  label: type,
}));
const WEATHER_OPTIONS = WEATHER_TYPES.map((weather) => ({
  value: weather,
  label: weather.toLowerCase(),
}));

function QueryPage({ setToken }) {
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState({});

  // Set axios header
  const axiosConfig = useMemo(() => {
    return {
      headers: {
        Authorization: ["Bearer", token].join(" "),
      },
    };
  }, [token]);

  const fetchUserInfo = () => {
    if (token) {
      axios
        .get("/api/user/auth", axiosConfig)
        .then((res) => {
          setUserInfo(res.data);
          if (userInfo.role === "viewer") {
            history.push("/");
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
        });
    }
  };

  const fetchLocations = () => {
    if (!token) {
      // No token, redirect to home page
      history.push("/");
    } else {
      // Fetch locations
      axios
        .get(URL, axiosConfig)
        .then((res) => {
          const l = res.data;
          setLocations(l);
          if (l.length > 0) selectLocation(l[0]);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
          history.push("/");
        });
    }
  };

  const toggleCheckbox = (checked) => {
    let newStatuses = [...checkStatuses];
    newStatuses[checked] = !newStatuses[checked];
    setCheckStatuses(newStatuses);
  };

  const ifTimeIsValid = (time) => {
    var timeToCheck;
    if (time === "start") timeToCheck = startTime;
    else if (time === "end") timeToCheck = endTime;
    else return false;

    if (
      timeToCheck === null ||
      timeToCheck === "" ||
      moment.isMoment(timeToCheck)
    )
      return true;
    else return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check everything is valid
    // Check data fields
    if (
      !selectedLocation ||
      checkStatuses.every((status) => status === false) ||
      !ifTimeIsValid("start") ||
      !ifTimeIsValid("end") ||
      !selectedTypes ||
      selectedTypes.length === 0 ||
      !selectedWeather ||
      selectedWeather.length === 0
    ) {
      setShowToast(true);
      return;
    }
    // Start putting query request together
    let payload = {};
    payload.data = [];
    if (checkStatuses[0] === true && selectedLocation.ROW === false)
      payload.data.push("traffic");
    if (checkStatuses[1] === true) payload.data.push("trespassing");
    if (checkStatuses[2] === true && selectedLocation.ROW === false)
      payload.data.push("signal");
    if (checkStatuses[3] === true) payload.data.push("trainevent");
    if (moment.isMoment(startTime)) payload.start_time = startTime.toDate();
    if (moment.isMoment(endTime)) payload.end_time = endTime.toDate();
    payload.types = selectedTypes.map((option) => option.value);
    payload.weather = selectedWeather.map((option) => option.value);

    // Send request
    axios
      .post(`/api/location/query/${selectedLocation._id}`, payload, {
        headers: axiosConfig.headers,
      })
      .then((res) => {
        setTicketId(res.data);
      })
      .catch((err) => {
        console.log(err);
        setShowToast(true);
      });
  };

  let history = useHistory();

  // Download <a> ref
  const downloadARef = useRef();

  // States
  const [locations, setLocations] = useState([]);
  const [selectedLocation, selectLocation] = useState(null);
  const [checkStatuses, setCheckStatuses] = useState([true, true, true, true]);
  const [showToast, setShowToast] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedWeather, selectWeather] = useState(WEATHER_OPTIONS);
  const [selectedTypes, selectTypes] = useState(DETECTION_OPTIONS);
  const [ticketId, setTicketId] = useState(null);

  useEffect(() => {
    fetchUserInfo();
    fetchLocations();
  }, [token]);

  useEffect(() => {
    if (ticketId) {
      if (downloadARef.current) {
        downloadARef.current.click();
      }
    }
  }, [ticketId]);

  if (!token) {
    history.push("/");
    return null;
  }

  return (
    <div className="single-page">
      <NavigationBar userInfo={userInfo} setToken={setToken} />
      <div className="box">
        <Container className="pt-2">
          {locations.length === 0 && (
            <Alert variant="primary">
              No locations. Please add new locations or contact your admin.
            </Alert>
          )}
          {locations.length > 0 && (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>
                  <b>Select location</b>
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => selectLocation(locations[e.target.value])}
                >
                  {locations.map((location, index) => (
                    <option value={index} key={index}>
                      ({location.feed.type.toUpperCase()}) - {location.name},{" "}
                      {location.city} {location.state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <b>Select data</b>
                </Form.Label>
                <div>
                  {!selectedLocation?.ROW && (
                    <Form.Check
                      inline
                      defaultChecked={checkStatuses[0]}
                      onChange={() => toggleCheckbox(0)}
                      label="Traffic"
                      type="checkbox"
                    />
                  )}
                  <Form.Check
                    inline
                    defaultChecked={checkStatuses[1]}
                    onChange={() => toggleCheckbox(1)}
                    label="Trespassing"
                    type="checkbox"
                  />
                  {!selectedLocation?.ROW && (
                    <Form.Check
                      inline
                      defaultChecked={checkStatuses[2]}
                      onChange={() => toggleCheckbox(2)}
                      label="Signal"
                      type="checkbox"
                    />
                  )}
                  <Form.Check
                    inline
                    defaultChecked={checkStatuses[3]}
                    onChange={() => toggleCheckbox(3)}
                    label="Train Event"
                    type="checkbox"
                  />
                </div>
                <Form.Text muted>
                  Select at least one.
                  {selectedLocation?.ROW
                    ? " This location is right of way."
                    : null}
                </Form.Text>
              </Form.Group>
              {selectedLocation && selectedLocation.feed.type === "live" && (
                <Form.Group>
                  <Form.Label>
                    <b>Select time period</b>
                  </Form.Label>
                  <div>
                    <Form.Label>Start time</Form.Label>
                    <Datetime
                      timeFormat={"HH:mm:ss"}
                      inputProps={{
                        placeholder: "Leave blank if not specified.",
                      }}
                      onChange={setStartTime}
                    />
                    {!ifTimeIsValid("start") && (
                      <Form.Text style={{ color: "red" }}>
                        Time is not valid.
                      </Form.Text>
                    )}
                    <Form.Label className="mt-2">End time</Form.Label>
                    <Datetime
                      timeFormat={"HH:mm:ss"}
                      inputProps={{
                        placeholder: "Leave blank if not specified.",
                      }}
                      onChange={setEndTime}
                    />
                    {!ifTimeIsValid("end") && (
                      <Form.Text style={{ color: "red" }}>
                        Time is not valid.
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>
                  <b>Select traffic types</b>
                </Form.Label>
                <Select
                  defaultValue={DETECTION_OPTIONS}
                  onChange={selectTypes}
                  options={DETECTION_OPTIONS}
                  isMulti={true}
                  closeMenuOnSelect={false}
                />
                <Form.Text muted>By default, all are selected.</Form.Text>
              </Form.Group>
              {selectedLocation && selectedLocation.feed.type === "live" && (
                <Form.Group>
                  <Form.Label>
                    <b>Select weather types</b>
                  </Form.Label>
                  <Select
                    defaultValue={WEATHER_OPTIONS}
                    onChange={selectWeather}
                    options={WEATHER_OPTIONS}
                    isMulti={true}
                    closeMenuOnSelect={false}
                  />
                  <Form.Text muted>By default, all are selected.</Form.Text>
                </Form.Group>
              )}
              <Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  type="submit"
                  disabled={
                    selectedLocation?.ROW === true
                      ? checkStatuses[1] === false && checkStatuses[3] === false
                      : checkStatuses.every((status) => status === false)
                  }
                >
                  Download
                </Button>
              </Form.Group>
            </Form>
          )}
        </Container>
        {/* A hidden <a> to initiate download */}
        <a
          ref={downloadARef}
          style={{ display: "none" }}
          href={`/api/location/query/${ticketId}?token=${token}`}
        >
          Download
        </a>
        {/* Toast goes here */}
        <div
          style={{
            position: "absolute",
            top: 70,
            right: 20,
          }}
        >
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={2000}
            autohide
          >
            <Toast.Header
              closeButton={false}
              style={{ backgroundColor: "#f8d7da" }}
            >
              Error
            </Toast.Header>
            <Toast.Body>Some fields are not valid, please check.</Toast.Body>
          </Toast>
        </div>
      </div>
    </div>
  );
}

export default QueryPage;
