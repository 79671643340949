import "../CSS/InfoContainer.css";
import "react-virtualized/styles.css";

import React, { useRef } from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Table,
  Column,
} from "react-virtualized";

// Set up duration format function
momentDurationFormatSetup(moment);

function timeFormatter(time) {
  const duration = moment.duration(time, "s");
  return duration.format("hh:mm:ss", { trim: false });
}

function formatFloat(float) {
  return float.toFixed(1);
}

function formatBlockageTime(seconds) {
  const duration = moment.duration(seconds, "s");
  return duration.format("h [hrs], m [mins], s [secs]");
}

function InfoTable({ sourceType, selectedTabName, data, token }) {
  var blockage_time = 0;

  if (selectedTabName === "signal") {
    const reducer = (acc, curr) => acc + curr.duration;
    blockage_time = data.reduce(reducer, 0);
  }

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  return (
    <React.Fragment>
      {selectedTabName === "signal" && (
        <Card className="mb-2">
          <Card.Body>
            The blockage time is:{" "}
            <b>
              <u>{formatBlockageTime(blockage_time)}</u>
            </b>
            .
          </Card.Body>
        </Card>
      )}
      <div className="table-wrapper">
        <AutoSizer>
          {({ width, height }) => (
            <>
              {selectedTabName === "traffic" && (
                <Table
                  width={width}
                  height={height}
                  headerHeight={30}
                  rowHeight={cache.current.rowHeight}
                  rowCount={data.length}
                  rowGetter={({ index }) => data[index]}
                  deferredMeasurementCache={cache.current}
                  rowClassName={({ index }) => {
                    return index === 0 ? "table-row newrow" : "table-row";
                  }}
                >
                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="index"
                    label="Index"
                    cellRenderer={({ rowIndex }) => {
                      return (
                        <span className="table-row-td">
                          {data.length - rowIndex}
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="type"
                    label="Type"
                    cellRenderer={({ dataKey, parent, rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <CellMeasurer
                          cache={cache.current}
                          columnIndex={0}
                          key={dataKey}
                          parent={parent}
                          rowIndex={rowIndex}
                        >
                          <div
                            style={{
                              paddingTop: 4,
                              paddingBottom: 4,
                            }}
                          >
                            <Badge variant="primary">
                              <span className="table-row-td">{row.type}</span>
                            </Badge>
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />

                  {sourceType === "live" && (
                    <Column
                      width={20}
                      flexGrow={1}
                      dataKey="weather"
                      label="Weather"
                      cellRenderer={({ rowIndex }) => {
                        const row = data[rowIndex];
                        return (
                          <Badge variant="secondary">
                            <span className="table-row-td">{row.weather}</span>
                          </Badge>
                        );
                      }}
                    />
                  )}

                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="time"
                    label="Time"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <Badge variant="light">
                          <span className="table-row-td">
                            {sourceType === "video"
                              ? timeFormatter(row.time)
                              : new Date(row.time).toLocaleString()}
                          </span>
                        </Badge>
                      );
                    }}
                  />
                </Table>
              )}

              {selectedTabName === "trespassing" && (
                <Table
                  width={width}
                  height={height}
                  headerHeight={30}
                  rowHeight={cache.current.rowHeight}
                  rowCount={data.length}
                  rowGetter={({ index }) => data[index]}
                  deferredMeasurementCache={cache.current}
                  rowClassName={({ index }) => {
                    return index === 0 ? "table-row newrow" : "table-row";
                  }}
                >
                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="index"
                    label="Index"
                    cellRenderer={({ rowIndex }) => {
                      return (
                        <span className="table-row-td">
                          {data.length - rowIndex}
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="type"
                    label="Type"
                    cellRenderer={({ dataKey, parent, rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <CellMeasurer
                          cache={cache.current}
                          columnIndex={0}
                          key={dataKey}
                          parent={parent}
                          rowIndex={rowIndex}
                        >
                          <div
                            style={{
                              paddingTop: 4,
                              paddingBottom: 4,
                            }}
                          >
                            <Badge variant="primary">
                              <span className="table-row-td">{row.type}</span>
                            </Badge>
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />

                  {sourceType === "live" && (
                    <Column
                      width={20}
                      flexGrow={1}
                      dataKey="weather"
                      label="Weather"
                      cellRenderer={({ rowIndex }) => {
                        const row = data[rowIndex];
                        return (
                          <Badge variant="secondary">
                            <span className="table-row-td">{row.weather}</span>
                          </Badge>
                        );
                      }}
                    />
                  )}

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="time"
                    label="Start Time"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <Badge variant="light">
                          <span className="table-row-td">
                            {sourceType === "video"
                              ? timeFormatter(row.start_time)
                              : new Date(row.start_time).toLocaleString()}
                          </span>
                        </Badge>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="duration"
                    label="Duration"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <span className="table-row-td">
                          {sourceType === "video"
                            ? (row.end_time - row.start_time).toFixed(1)
                            : (
                                (new Date(row.end_time) -
                                  new Date(row.start_time)) /
                                1000
                              ).toFixed(1)}
                          s
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="clip"
                    label="Clip"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <span className="table-row-td">
                          <a
                            href={`/api/clips/trespassing/${row._id}?token=${token}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.clip_filename}
                          </a>
                        </span>
                      );
                    }}
                  />
                </Table>
              )}

              {selectedTabName === "signal" && (
                <Table
                  width={width}
                  height={height}
                  headerHeight={30}
                  rowHeight={cache.current.rowHeight}
                  rowCount={data.length}
                  rowGetter={({ index }) => data[index]}
                  deferredMeasurementCache={cache.current}
                  rowClassName={({ index }) => {
                    return index === 0 ? "table-row newrow" : "table-row";
                  }}
                >
                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="index"
                    label="Index"
                    cellRenderer={({ rowIndex }) => {
                      return (
                        <span className="table-row-td">
                          {data.length - rowIndex}
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="time"
                    label="Start Time"
                    cellRenderer={({ dataKey, parent, rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <CellMeasurer
                          cache={cache.current}
                          columnIndex={0}
                          key={dataKey}
                          parent={parent}
                          rowIndex={rowIndex}
                        >
                          <div
                            style={{
                              paddingTop: 4,
                              paddingBottom: 4,
                            }}
                          >
                            <Badge variant="light">
                              <span className="table-row-td">
                                {sourceType === "video"
                                  ? timeFormatter(row.start_time)
                                  : new Date(row.start_time).toLocaleString()}
                              </span>
                            </Badge>
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="time"
                    label="Start Time"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <Badge variant="light">
                          <span className="table-row-td">
                            {sourceType === "video"
                              ? timeFormatter(row.end_time)
                              : new Date(row.end_time).toLocaleString()}
                          </span>
                        </Badge>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="duration"
                    label="Duration"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <span className="table-row-td">
                          {formatFloat(row.duration)}s
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="clip"
                    label="Clip"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <span className="table-row-td">
                          <a
                            href={`/api/clips/signal/${row._id}?token=${token}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.clip_filename}
                          </a>
                        </span>
                      );
                    }}
                  />
                </Table>
              )}

              {selectedTabName === "train" && (
                <Table
                  width={width}
                  height={height}
                  headerHeight={30}
                  rowHeight={cache.current.rowHeight}
                  rowCount={data.length}
                  rowGetter={({ index }) => data[index]}
                  deferredMeasurementCache={cache.current}
                  rowClassName={({ index }) => {
                    return index === 0 ? "table-row newrow" : "table-row";
                  }}
                >
                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="index"
                    label="Index"
                    cellRenderer={({ rowIndex }) => {
                      return (
                        <span className="table-row-td">
                          {data.length - rowIndex}
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="time"
                    label="Start Time"
                    cellRenderer={({ dataKey, parent, rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <CellMeasurer
                          cache={cache.current}
                          columnIndex={0}
                          key={dataKey}
                          parent={parent}
                          rowIndex={rowIndex}
                        >
                          <div
                            style={{
                              paddingTop: 4,
                              paddingBottom: 4,
                            }}
                          >
                            <Badge variant="light">
                              <span className="table-row-td">
                                {sourceType === "video"
                                  ? timeFormatter(row.start_time)
                                  : new Date(row.start_time).toLocaleString()}
                              </span>
                            </Badge>
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="time"
                    label="Start Time"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <Badge variant="light">
                          <span className="table-row-td">
                            {sourceType === "video"
                              ? timeFormatter(row.end_time)
                              : new Date(row.end_time).toLocaleString()}
                          </span>
                        </Badge>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={1}
                    dataKey="duration"
                    label="Duration"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <span className="table-row-td">
                          {formatFloat(row.duration)}s
                        </span>
                      );
                    }}
                  />

                  <Column
                    width={20}
                    flexGrow={2}
                    dataKey="clip"
                    label="Clip"
                    cellRenderer={({ rowIndex }) => {
                      const row = data[rowIndex];
                      return (
                        <span className="table-row-td">
                          <a
                            href={`/api/clips/train/${row._id}?token=${token}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.clip_filename}
                          </a>
                        </span>
                      );
                    }}
                  />
                </Table>
              )}
            </>
          )}
        </AutoSizer>
      </div>
      {data.length === 0 && (
        <p className="mt-2" style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          No events.
        </p>
      )}
    </React.Fragment>
  );
}

export default InfoTable;
