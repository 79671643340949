import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import "../CSS/Navbar.scss";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const ROLE_NAMES = {
  root: "System Root User",
  admin: "Organization Admin",
  viewer: "Viewer",
};

const ROLE_BADGE_VARIANTS = {
  root: "danger",
  admin: "success",
  viewer: "secondary",
};

function NavigationBar({ userInfo, setToken }) {
  let history = useHistory();
  let match = useRouteMatch();

  const logout = () => {
    // Clear localStorage
    localStorage.removeItem("token");
    setToken(null);
    history.replace("/");
  };

  return (
    <Navbar expand="lg" className="main-nav">
      <Navbar.Brand>Rutgers Rail and Transit Program</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/locations" active={match.path === "/locations"}>
            Locations
          </Nav.Link>
          {(userInfo.role === "root" || userInfo.role === "admin") && (
            <Nav.Link href="/query" active={match.path === "/query"}>
              Query
            </Nav.Link>
          )}
          {(userInfo.role === "root" || userInfo.role === "admin") && (
            <Nav.Link href="/analytics" active={match.path === "/analytics"}>
              Analytics
            </Nav.Link>
          )}
          {(userInfo.role === "root" || userInfo.role === "admin") && (
            <Nav.Link href="/logs" active={match.path === "/logs"}>
              Logs
            </Nav.Link>
          )}
          {(userInfo.role === "root" || userInfo.role === "admin") && (
            <Nav.Link href="/admin" active={match.path === "/admin"}>
              Admin Page
            </Nav.Link>
          )}
        </Nav>
        <Form inline>
          <Navbar.Text className="mr-2">
            <b>{userInfo.name}</b>
          </Navbar.Text>
          <Navbar.Text className="mr-2">
            <Badge
              variant={ROLE_BADGE_VARIANTS[userInfo.role]}
              style={{ fontSize: "1rem" }}
            >
              {ROLE_NAMES[userInfo.role]}
            </Badge>
          </Navbar.Text>
          <Button variant="outline-light" onClick={logout}>
            Logout
          </Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
