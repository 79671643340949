import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./CSS/App.scss";
import "./scss/custom.scss";

import LoginPage from "./Components/LoginPage";
import LocationsPage from "./Components/LocationsPage";
import LocationPage from "./Components/LocationPage";
import LocationInfoPage from "./Components/LocationInfoPage";
import QueryPage from "./Components/QueryPage";
import LogsPage from "./Components/LogsPage";
import AdminPage from "./Components/AdminPage";
import AnalyticsPage from "./Components/AnalyticsPage";

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginPage token={token} setToken={setToken} />
          </Route>
          <Route exact path="/locations">
            <LocationsPage token={token} setToken={setToken} />
          </Route>
          <Route exact path="/locations/add">
            <LocationInfoPage token={token} />
          </Route>
          <Route exact path="/query">
            <QueryPage token={token} />
          </Route>
          <Route exact path="/analytics">
            <AnalyticsPage token={token} />
          </Route>
          <Route path="/locations/edit/:id">
            <LocationInfoPage token={token} />
          </Route>
          <Route path="/location/:id">
            <LocationPage token={token} setToken={setToken} />
          </Route>
          <Route path="/logs">
            <LogsPage token={token} setToken={setToken} />
          </Route>
          <Route path="/admin">
            <AdminPage token={token} setToken={setToken} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
