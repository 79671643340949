import '../CSS/LogsPage.css';
import "react-datepicker/dist/react-datepicker.css";

import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import isToday from 'date-fns/isToday';
import endOfDay from 'date-fns/endOfDay';

import NavigationBar from './Navbar';
import Container from 'react-bootstrap/Container';
import { ArrowClockwise } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import DatePicker from "react-datepicker";
import Plot from 'react-plotly.js';

momentDurationFormatSetup(moment);

const locationURL = '/api/location/all';
const logURL = '/api/log'

function LogsPage ({ setToken }) {
  const token = localStorage.getItem('token');
  const [userInfo, setUserInfo] = useState({});

  let history = useHistory();

  const [date, setDate] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [selectedLocation, selectLocation] = useState(null);
  const [logs, setLogs] = useState([]);
  const [plotData, setPlotData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // Set axios header
  const axiosConfig = useMemo(() => {
    return {
      headers: {
        Authorization: ["Bearer", token].join(" "),
      },
    };
  }, [token]);

  const fetchUserInfo = () => {
    if (token) {
      axios
        .get("/api/user/auth", axiosConfig)
        .then((res) => {
          setUserInfo(res.data);
          if (userInfo.role === "viewer") {
            history.push("/");
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
        });
    }
  };

  const fetchLocations = () => {
    if (!token) {
      // No token, redirect to home page
      history.push('/');
    } else {
      // Fetch locations
      axios.get(locationURL, axiosConfig)
        .then(res => {
          let l = res.data;
          l = l.filter(location => location.feed.type === 'live');
          setLocations(l);
          if (l.length > 0) selectLocation(l[0]);
        })
        .catch(err => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem('token');
          }
          history.push('/');
        });
    }
  }

  const fetchLogs = () => {
    if (!selectedLocation) return;

    setIsLoading(true);

    const url = `${logURL}/${selectedLocation._id}/${date.toISOString()}`;
    if (!token) {
      // No token, redirect to home page
      history.push('/');
    } else {
      // Fetch locations
      axios.get(url, axiosConfig)
        .then(res => {
          let logs = res.data;
          let pDataX = [];
          let pDataY = [];
          for (let log of logs) {
            pDataX.push(new Date(log.timestamp));
            pDataY.push(log.level === 'info' ? 1 : 0);
          }
          setPlotData([
            {
              type: 'scatter',
              mode: 'lines+markers',
              x: pDataX,
              y: pDataY
            }
          ])
          setLogs(logs);

          setIsLoading(false);
        })
        .catch(err => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            history.push('/');
          }

          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    fetchUserInfo();
    fetchLocations();
  }, [token]);

  useEffect(() => {
    fetchLogs();
  }, [selectedLocation, date]);

  const Row = ({ index, style }) => (
    <div
      className={classNames({
        'green-message': logs[index].level === 'info',
        'red-message': logs[index].level === 'error',
      })}
      style={{
        border: '1px solid #ced4da',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...style
      }}
    >
      <span style={{ display: 'block', flexGrow: 0 }}>{new Date(logs[index].timestamp).toLocaleString()}</span>
      <span style={{ display: 'block', flexGrow: 1, paddingLeft: 20 }}>{logs[index].message}</span>
    </div>
  );

  return (
    <div className="single-page">
      <NavigationBar userInfo={userInfo} setToken={setToken} />
      <div className="box">
        <Container style={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
          {
            locations.length === 0 && 
            <Alert variant="primary">
              No locations. Please add new locations.
            </Alert>
          }
          {
            locations.length > 0 &&
            <div>
              <Form>
                <Form.Group>
                  <Form.Label><b>Select location</b></Form.Label>
                  <Form.Control as="select" onChange={e => selectLocation(locations[e.target.value])}>
                    { locations.map((location, index) => <option value={index} key={index}>({location._id}) - {location.name}, {location.city} {location.state}</option>) }
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
          }
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
          {
            locations.length > 0 &&
            <>
              <DatePicker selected={date} onChange={date => setDate(date)} />
              <Button
                className="ml-2"
                variant="outline-dark"
                size="sm"
                onClick={fetchLogs}
              >
                <ArrowClockwise />
              </Button>
            </>
          }
          {
            logs.length > 0 &&
            <span style={{ marginLeft: 20 }}>Down time since start of day until { isToday(date) ? 'now' : 'end of day' }: { isLoading ? "Loading..." : humanizeTime(calculateDownTime(logs, date)) }</span>
          }
          </div>
          {
            logs.length === 0 &&
            <h3 style={{ marginTop: 20 }}>No logs.</h3>
          }
          {
            logs.length > 0 &&
            <div style={{ maxHeight: '30%' }}>
              <Plot
                style={{ width: '100%', height: '100%' }}
                data={plotData}
                layout={{
                  autosize: true,
                  barmode: 'stack',
                  margin: {
                    t: 0,
                  },
                  yaxis: {
                    visible: false
                  }
                }}
                config={{
                  displayModeBar: false
                }}
                useResizeHandler={true}
              />
            </div>
          }
          {
            logs.length > 0 &&
            <div style={{ flexGrow: 1 }}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={logs.length}
                    itemSize={35}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </div>
          }
        </Container>
      </div>
    </div>
  );
}

function calculateDownTime(logs, date) {
  let reversedLogs = [...logs].reverse();
  let downTime = 0;
  let d = new Date(date.getTime());
  d.setHours(0, 0, 0, 0);   // Get start of day
  if (logs.length === 0) return 0;
  let first = logs[0];
  let status;
  if (first.level === 'info') {
    status = 'down';
  } else {
    status = 'up';
  }
  for (let log of reversedLogs) {
    if (status === 'down' && log.level === 'info') {
      let time = new Date(log.timestamp);
      downTime = downTime + (time - d);
      status = 'up';
    } else if (status === 'up' && log.level === 'error') {
      let time = new Date(log.timestamp);
      d = time;
      status = 'down';
    }
  }
  if (status === 'down') {
    let time;
    if (isToday(date)) {
      time = new Date();
    } else {
      time = endOfDay(date);
    }
    downTime = downTime + (time - d);
  }
  return downTime / 1000;   // return seconds
}

function humanizeTime(seconds) {
  const duration = moment.duration(seconds, 's');
  return duration.format('h [hrs], m [mins], s [secs]');
}

export default LogsPage;