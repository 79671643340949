import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import "../CSS/LocationsPage.css";
import NavigationBar from "./Navbar";
import Badge from "react-bootstrap/Badge";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";

const URL = "/api/location/all";

// Status badge variants
const statusBadgeVariants = {
  stopped: "danger",
  processing: "primary",
  complete: "success",
  create: "dark",
  scheduled: "info"
};

function LocationsPage({ setToken }) {
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState({});

  // Set axios header
  const axiosConfig = useMemo(() => {
    return {
      headers: {
        Authorization: ["Bearer", token].join(" "),
      },
    };
  }, [token]);

  const fetchUserInfo = () => {
    if (token) {
      axios
        .get("/api/user/auth", axiosConfig)
        .then((res) => {
          setUserInfo(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
        });
    }
  };

  const fetchLocations = () => {
    if (!token) {
      // No token, redirect to home page
      history.push("/");
    } else {
      // Fetch locations
      axios
        .get(URL, axiosConfig)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
          history.push("/");
        });
    }
  };

  let history = useHistory();
  const [locations, setLocations] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState(null);

  useEffect(() => {
    fetchUserInfo();
    fetchLocations();
  }, [token]);

  const handleRowClick = (id) => {
    // Go to location page
    history.push(`/location/${id}`);
  };

  const handleAdd = (id) => {
    history.push("/locations/add");
  };

  const handleEdit = (id) => {
    history.push(`/locations/edit/${id}`);
  };

  const handleDelete = () => {
    axios
      .delete(`/api/location/${idToBeDeleted}`, axiosConfig)
      .then((res) => {
        // Refresh locations
        fetchLocations();
      })
      .catch((err) => {
        // Toast delete failed
        console.log(err);
      })
      .then(() => {
        // Close delete modal
        setIdToBeDeleted(null);
        setShowDeleteModal(false);
      });
  };

  return (
    <div className="single-page">
      <NavigationBar userInfo={userInfo} setToken={setToken} />
      <div className="box">
        <div className="locations">
          <div className="wrap">
            <div className="header-bar">
              <h1>Locations</h1>
              {(userInfo.role === "root" || userInfo.role === "admin") && (
                <Button
                  variant="outline-success"
                  className="ml-2"
                  onClick={handleAdd}
                >
                  Add
                </Button>
              )}
            </div>
            {locations.length === 0 && (
              <p>
                No locations.{" "}
                {(userInfo.role === "root" || userInfo.role === "admin") &&
                  "Please add new locations."}
              </p>
            )}
            {locations.length > 0 && (
              <Table className="location-list" bordered responsive>
                <thead className="table-header">
                  <tr>
                    <th>Name</th>
                    <th>Location Type</th>
                    <th>Status</th>
                    <th>Feed Type</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {locations.map((location) => {
                    return (
                      <tr key={location._id}>
                        <td>{location.name}</td>
                        <td>
                          <Badge
                            variant={
                              location.ROW === true ? "secondary" : "primary"
                            }
                          >
                            {location.ROW === true
                              ? "Right Of Way"
                              : "Grade Crossing"}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            pill
                            variant={statusBadgeVariants[location.status]}
                          >
                            {location.status}
                          </Badge>
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {location.feed.type}
                        </td>
                        <td>{location.city}</td>
                        <td>{location.state}</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              variant="primary"
                              onClick={() => handleRowClick(location._id)}
                            >
                              View
                            </Button>
                            {(userInfo.role === "root" ||
                              userInfo.role === "admin") && (
                              <Button
                                variant="warning"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEdit(location._id);
                                }}
                              >
                                Edit
                              </Button>
                            )}

                            {(userInfo.role === "root" ||
                              userInfo.role === "admin") && (
                              <Button
                                variant="danger"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIdToBeDeleted(location._id);
                                  setShowDeleteModal(true);
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
          {/* Delete Modal Goes here */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <b>All</b> data for this location will be deleted. Are you sure
              you want to delete the location?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Close
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default LocationsPage;
