import "../CSS/VideoContainer.css";

import React, { createRef } from "react";
import axios from "axios";
import flvjs from "flv.js";
import DPlayer from "dplayer";

const REFRESH_INTERVAL = 1000;
const HOST = "/api";

class VideoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.location = null;
    this.player = null;
    this.timer = null; // Timer for refresh video status
    this.videoObject = createRef();
    // Set axios header
    this.axiosConfig = {
      headers: {
        Authorization: ["Bearer", props.token].join(" "),
      },
    };
    // Bind this to handlers
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.handleSeeked = this.handleSeeked.bind(this);
  }

  componentDidMount() {
    window.flvjs = flvjs; // Load flv.js for player
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  async setup() {
    // Get location info from id
    let location = await axios.get(
      `${HOST}/location/${this.props.location_id}`,
      this.axiosConfig
    );
    location = location.data;
    this.location = location;
    if (location.feed.type === "live") {
      // Get stream link
      let flvURL = await axios.get(
        `${HOST}/location/stream/${this.props.location_id}`,
        this.axiosConfig
      );
      flvURL = flvURL.data.url;
      // Setup player
      if (flvjs.isSupported()) {
        if (this.videoObject.current) {
          var videoElement = this.videoObject.current;
          var flvPlayer = flvjs.createPlayer({
              type: 'flv',
              url: `//${flvURL}`,
              isLive: true,
          });
          flvPlayer.attachMediaElement(videoElement);
          flvPlayer.load();

          // Mute before autoplay
          videoElement.muted = true;
          flvPlayer.play();
        }
      }
    } else if (location.feed.type === "video") {
      if (location.status === "stopped") {
        // Hasn't started processing yet, try setup again later
        setTimeout(() => {
          this.setup();
        }, REFRESH_INTERVAL);
      } else if (location.status === "processing") {
        // Setup player
        this.player = new DPlayer({
          container: document.getElementById("player"),
          video: {
            url: `${HOST}/location/video/${location._id}?token=${this.props.token}`,
          },
          loop: false,
          autoplay: false,
          screenshot: true,
          volume: 0,
          contextmenu: [
            {
              text: "Refresh",
              click: (player) => {
                player.video.currentTime = player.video.seekable.start(
                  player.video.seekable.length - 1
                );
              },
            },
          ],
        });
        // Seek to current processed time
        this.player.seek(location.processed_time);
        this.player.video.muted = true;
        this.player.video.play();
        // Handle timeupdate event
        this.player.on("timeupdate", this.handleTimeUpdate);
        // Handle seeked event
        this.player.on("seeked", this.handleSeeked);
        // Set timer to refresh location data
        this.timer = setInterval(() => {
          this.refreshLocationInfo();
        }, REFRESH_INTERVAL);
      } else if (location.status === "complete") {
        // Just play through
        // Setup player
        this.player = new DPlayer({
          container: document.getElementById("player"),
          video: {
            url: `${HOST}/location/video/${location._id}?token=${this.props.token}`,
          },
          loop: false,
          autoplay: false,
          screenshot: true,
          volume: 0,
          contextmenu: [
            {
              text: "Refresh",
              click: (player) => {
                player.video.currentTime = player.video.seekable.start(
                  player.video.seekable.length - 1
                );
              },
            },
          ],
        });
        // Auto play
        this.player.on("canplaythrough", () => {
          this.player.video.muted = true;
          this.player.video.play();
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  handleTimeUpdate() {
    if (this.player) {
      if (this.player.video.currentTime >= this.location.processed_time)
        this.player.pause(); // Pause when playing faster than processing speed.
    }
  }

  handleSeeked() {
    if (this.player) {
      if (this.player.video.currentTime >= this.location.processed_time)
        this.player.seek(this.location.processed_time); // Prevent user from seek to time where hasn't been processed yet
    }
  }

  async refreshLocationInfo() {
    // Refresh location
    let location = await axios.get(
      `${HOST}/location/${this.props.location_id}`,
      this.axiosConfig
    );
    location = location.data;
    this.location = location;
    if (this.player) {
      if (
        this.player.video.currentTime < location.processed_time &&
        this.player.video.paused
      )
        this.player.play(); // Continue to play is paused and more processed data is available
    }
  }

  render() {
    return (
      <div
        id="player"
        className={this.props.fullpage ? "video-container-full" : "video-container"}
      >
        <video className="player" controls ref={this.videoObject} />
      </div>
    );
  }
}

export default VideoContainer;
