import "../CSS/LoginPage.css";
import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      showAlert: false,
    };
  }

  componentDidMount() {
    // If has token, directly redirect to locations page
    const token = localStorage.getItem("token");
    const axiosConfig = {
      headers: {
        Authorization: ["Bearer", token].join(" "),
      },
    };
    axios
      .get("/api/user/auth", axiosConfig)
      .then((res) => {
        this.props.history.push("/locations");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // Unauthorized
          console.error(err);
          localStorage.removeItem("token");
        }
      });
  }

  handleSubmit() {
    fetch("/api/user/auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      }),
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        else
          this.setState({
            showAlert: true,
          });
      })
      .then((data) => {
        this.props.setToken(data.token);
        localStorage.setItem("token", data.token);
        this.props.history.push("/locations");
      })
      .catch((err) => {
        this.setState({
          showAlert: true,
        });
      });
  }

  render() {
    return (
      <div className="login text-center">
        <Form
          className="sign-in"
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <h1>Rutgers Rail and Transit Program</h1>
          <h3>
            An Artificial Intelligence-Aided System for Automated Trespassing
            Detection
          </h3>
          <Alert variant="danger" show={this.state.showAlert}>
            Bad credentials!
          </Alert>
          <Form.Label className="mt-3">Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e) => this.setState({ username: e.target.value })}
          />
          <Form.Label className="mt-2">Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <Button size="lg" className="mt-3 login-button" type="submit">
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default withRouter(LoginPage);
