import React from 'react';
import { withRouter } from 'react-router-dom';

import axios from 'axios';
import p5 from 'p5';

import '../CSS/LocationInfoPage.css';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

const US_STATES_LIST = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 
  'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID',
  'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
  'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR',
  'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA',
  'WV', 'WI', 'WY' ];
let US_STATES_OPTIONS = US_STATES_LIST.map(state => ({ value: state, label: state }));

class LocationInfoPage extends React.Component {
  constructor(props) {
    super(props);
    // Set axios header
    this.axiosConfig = {
      headers: {
        'Authorization': ['Bearer', this.props.token].join(' ')
      }
    };
    // States
    this.state = {
      isEditing: false,
      step: 'create',
      button_active: [true, false],
      showAlert: false,
      showUploading: false,
      showCreating: false,
      name: null,
      city: null,
      state: null,
      live: false,
      ROW: false,
      liveURL: '',
      id: null,
      dimensions: null,
      drawing_status: 'zone',
      zone_points: [],
      traffic_lights: []
    }
    // If is editting, get the default information
    const { match } = this.props;
    if (match.path === '/locations/edit/:id') {
      // Is editing
      this.state.isEditing = true
      this.state.id = match.params.id
    }
    // Canvas ref
    this.canvas = null;
  }

  componentDidMount() {
    // Get location information and populate the form
    if (this.state.isEditing)
    {
      axios.get(`/api/location/${this.state.id}`, this.axiosConfig)
        .then(res => {
          const data = res.data;
          this.setState({
            name: data.name,
            city: data.city,
            state: data.state,
            live: data.feed.type === 'live',
            ROW: data.ROW,
            liveURL: data.feed.type === 'live' ? data.feed.name : '',
            zone_points: data.ROI.zone_points || [],
            traffic_lights: data.ROI.traffic_lights || []
          });
        })
        .catch(err => {
          console.error(err);
          this.props.history.push('/locations');    // Redirect back to locations
        })
    }
  }

  handleButtonClick(i) {
    let newButtonActive = [false, false];
    newButtonActive[i] = true;
    let newDrawingStatus = i === 0 ? 'zone' : 'traffic_light';
    this.setState({
      button_active: newButtonActive,
      drawing_status: newDrawingStatus
    });
  }

  handleUndo() {
    if (this.state.drawing_status === 'zone') {
      this.setState({
        zone_points: this.state.zone_points.slice(0, -1)
      });
    } else if (this.state.drawing_status === 'traffic_light') {
      this.setState({
        traffic_lights: this.state.traffic_lights.slice(0, -1)
      });
    }
  }

  handleClear() {
    if (this.state.drawing_status === 'zone') {
      this.setState({
        zone_points: []
      });
    } else if (this.state.drawing_status === 'traffic_light') {
      this.setState({
        traffic_lights: []
      });
    }
  }

  async handleCreate(doesHandle = true) {
    if (doesHandle)
    {
      // Won't skip
      var URL = '';
      if (this.state.isEditing === true)
        URL = `/api/location/update/${this.state.id}`;
      else
        URL = '/api/location/new';
      
      if (this.state.live)
      {
        // Location is live
        // Show creating spinner
        this.setState({ showCreating: true });
        let location = await axios.post(URL, {
          name: this.state.name,
          city: this.state.city,
          state: this.state.state,
          ROW: this.state.ROW,
          feed: {
            type: 'live',
            name: this.state.liveURL
          },
        }, this.axiosConfig);
        location = location.data;
        let dimensions = await axios.get(`/api/location/video/dimensions/${location._id}`, this.axiosConfig);
        dimensions = dimensions.data;
        // Prepare canvas
        const backgroundImgURL = `/api/location/video/screenshot/${location._id}?token=${this.props.token}`;
        this.prepareCanvas(dimensions, backgroundImgURL);
        this.setState({
          id: location._id,
          step: 'ROI',
          dimensions: dimensions
        });
      }
      else {
        // Location is video
        let location = await axios.post(URL, {
          name: this.state.name,
          city: this.state.city,
          state: this.state.state,
          ROW: this.state.ROW,
        }, this.axiosConfig);
        location = location.data;
        this.setState({
          step: 'upload',
          id: location._id
        });
      }
    }
    else
    {
      // Skip this step
      if (this.state.live) {
        // Show creating spinner
        this.setState({ showCreating: true });
        // If live, jump to ROI page
        let dimensions = await axios.get(`/api/location/video/dimensions/${this.state.id}`, this.axiosConfig);
        dimensions = dimensions.data;
        // Prepare canvas
        const backgroundImgURL = `/api/location/video/screenshot/${this.state.id}?token=${this.props.token}`;
        this.prepareCanvas(dimensions, backgroundImgURL);
        this.setState({
          showCreating: false,
          step: 'ROI',
          dimensions: dimensions
        });
      } else {
        // If video, jump to upload page
        this.setState({
          step: 'upload',
        });
      }
    }
  }

  async handleUpload(doesHandle = true) {
    if (doesHandle)
    {
      // Show uploading spinner
      this.setState({
        showUploading: true
      });
      
      let input = document.getElementById('video');
      let file = input.files[0];
      if (!file)
        return
      let formData = new FormData();
      formData.append('file', file);
      try {
        await axios.post(`/api/location/video/upload/${this.state.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': this.axiosConfig.headers.Authorization
          }
        });
        let dimensions = await axios.get(`/api/location/video/dimensions/${this.state.id}`, this.axiosConfig);
        dimensions = dimensions.data;
        // Prepare canvas
        const backgroundImgURL = `/api/location/video/screenshot/${this.state.id}?token=${this.props.token}`;
        this.prepareCanvas(dimensions, backgroundImgURL);
        this.setState({
          step: 'ROI',
          dimensions: dimensions
        });
      } catch (err) {
        console.error(err);
      }
    }
    else
    {
      // Skip upload, jump to draw ROI
      let dimensions = await axios.get(`/api/location/video/dimensions/${this.state.id}`, this.axiosConfig);
      dimensions = dimensions.data;
      // Prepare canvas
      const backgroundImgURL = `/api/location/video/screenshot/${this.state.id}?token=${this.props.token}`;
      this.prepareCanvas(dimensions, backgroundImgURL);
      this.setState({
        step: 'ROI',
        dimensions: dimensions
      });
    }
  }

  prepareCanvas(dimensions, backgroundImgURL) {
    const s = (p) => {
      class Point {
        constructor(x, y, color) {
          this.x = x;
          this.y = y;
          this.color = color;
        }
      
        display () {
          p.push();
          p.fill(this.color || red);
          p.circle(this.x, this.y, 10);
          p.pop();
        }
      }
      
      // Handle editing
      if (this.state.isEditing)
      {
        this.setState({
          zone_points: this.state.zone_points.map(point => new Point(point.x, point.y)),
          traffic_lights: this.state.traffic_lights.map(point => new Point(point.x, point.y, p.color(255, 0, 255)))
        });
      }

      let cnv;
      var img;

      let red = p.color(255, 0, 0);
      
      var handleMouseClicked = () => {
        if (this.state.drawing_status === 'zone') {
          // Drawing ROI
          if (this.state.zone_points.length < 4) {
            // Only allow drawing if no more than 4 points exist on the canvas
            this.setState({
              zone_points: [...this.state.zone_points, new Point(p.mouseX, p.mouseY)]
            });
          }
        } else if (this.state.drawing_status === 'traffic_light') {
          // Drawing Traffic Light
          if (this.state.traffic_lights.length < 2) {
            // Only allow drawing if no more than 2 points exist on the canvas
            this.setState({
              traffic_lights: [...this.state.traffic_lights, new Point(p.mouseX, p.mouseY, p.color(255, 0, 255))]
            });
          }
        }
      }

      p.preload = () => {
        img = p.loadImage(backgroundImgURL);
      }

      p.setup = () => {
        cnv = p.createCanvas(dimensions.width, dimensions.height);
        cnv.mouseClicked(handleMouseClicked);
      }

      p.draw = () => {
        // Draw background
        p.image(img, 0, 0);
        // Draw ROI zone points
        for (let i = 0; i < this.state.zone_points.length; i++) {
          this.state.zone_points[i].display();
          // Connect zone points
          let nextPointIndex = (i + 1) % 4;
          if (nextPointIndex <= this.state.zone_points.length - 1) {
            p.push();
            p.strokeWeight(3);
            p.stroke(red);
            p.line(this.state.zone_points[i].x, this.state.zone_points[i].y, this.state.zone_points[nextPointIndex].x, this.state.zone_points[nextPointIndex].y);
            p.pop();
          }
        }
        // Draw traffic lights
        for (let i = 0; i < this.state.traffic_lights.length; i++) {
          this.state.traffic_lights[i].display();
        }
        if (this.state.zone_points.length === 4) {
          // Draw quad
          p.push();
          p.fill(255, 0, 0, 255 / 2);
          p.noStroke();
          p.quad(this.state.zone_points[0].x, this.state.zone_points[0].y, this.state.zone_points[1].x, this.state.zone_points[1].y, this.state.zone_points[2].x, this.state.zone_points[2].y, this.state.zone_points[3].x, this.state.zone_points[3].y,)
          p.pop();
        }
      }

      p.keyPressed = () => {
        // press U to undo
        if (p.keyCode === 85) this.handleUndo();
        // press C to clear
        if (p.keyCode === 67) this.handleClear();
      }

      
    }
    // Set up canvas
    this.canvas = new p5(s, 'canvas');
  }

  async handleSubmit(doesHandle = true) {
    if (doesHandle)
    {
      if (this.state.ROW === false)
      {
        // Regular grade crossing
        if (this.state.zone_points.length < 4 || this.state.traffic_lights.length < 2) {
          // Drawing incomplete, show alert
          this.setState({
            showAlert: true
          });
          // Auto hide alert in 3000ms
          setTimeout(() => {
            this.setState({
              showAlert: false
            });
          }, 3000);
        } else {
          // save ROI to database
          let zone_points = this.state.zone_points.map((point) => {
            return {
              x: point.x,
              y: point.y
            };
          });
          let traffic_lights = this.state.traffic_lights.map((point) => {
            return {
              x: point.x,
              y: point.y
            };
          });
          let payload = { zone_points, traffic_lights };
          try {
            await axios.post(`/api/location/ROI/${this.state.id}`, payload, this.axiosConfig);
          } catch (e) {
            console.error(e);
          } finally {
            this.props.history.push('/locations');    // Redirect to location page
          }
        }
      }
      else
      {
        // Right of way crossing
        if (this.state.zone_points.length < 4) {
          // Drawing incomplete, show alert
          this.setState({
            showAlert: true
          });
          // Auto hide alert in 3000ms
          setTimeout(() => {
            this.setState({
              showAlert: false
            });
          }, 3000);
        } else {
          // save ROI to database
          let zone_points = this.state.zone_points.map((point) => {
            return {
              x: point.x,
              y: point.y
            };
          });
          let payload = { zone_points };
          try {
            await axios.post(`/api/location/ROI/${this.state.id}`, payload, this.axiosConfig);
          } catch (e) {
            console.error(e);
          } finally {
            this.props.history.push('/locations/');    // Redirect to location page
          }
        }
      }
    }
    else {
      // Skip draw ROI, jump to location page
      this.props.history.push(`/location/${this.state.id}`);
    }
  }

  render() {
    return (
      <div className="new-location">
      {
        this.state.step === 'create' && 
        <Form className="new-location-form">
          <h1>{ this.state.isEditing ? 'Edit location' : 'Set up new location' }</h1>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Name" defaultValue={this.state.name} onChange={(e) => this.setState({name: e.target.value})} />
          </Form.Group>
          <Form.Group>
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="City" defaultValue={this.state.city} onChange={(e) => this.setState({city: e.target.value})} />
          </Form.Group>
          <Form.Group>
            <Form.Label>State</Form.Label>
            <Select
              value={ this.state.state ? { label: this.state.state, value: this.state.state } : null}
              onChange={option => this.setState({ state: option.value })}
              options={US_STATES_OPTIONS}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              id="ROW-switch"
              type="switch"
              label="right of way?"
              checked={this.state.ROW}
              onChange={() => this.setState({ ROW: !this.state.ROW })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              id="live-switch"
              type="switch"
              label="live stream?"
              checked={this.state.live}
              onChange={() => this.setState({ live: !this.state.live })}
            />
          </Form.Group>
          {
            this.state.live && 
            <Form.Group>
              <Form.Label>Live stream URL</Form.Label>
              <Form.Control type="text" placeholder="URL" defaultValue={this.state.liveURL} onChange={(e) => this.setState({liveURL: e.target.value})} />
            </Form.Group>
          }
          { this.state.showCreating && <Spinner animation="border" className="mb-2" /> }
          <Form.Group>
            { this.state.isEditing && <Button size="lg" variant="dark" className="mr-2" onClick={() => this.handleCreate(false)}>Skip</Button> }
            <Button size="lg" onClick={() => this.handleCreate(true)}>Save</Button>
          </Form.Group>
        </Form>
      }
      {
        this.state.step === 'upload' && 
        <Form className="new-location-form">
          <h1>Upload video</h1>
          { this.state.showUploading && <Spinner animation="border" /> }
          <Form.Group>
            <Form.File id="video" label="File" />
          </Form.Group>
          <Form.Group>
            { this.state.isEditing && <Button size="lg" variant="dark" className="mr-2" onClick={() => this.handleUpload(false)}>Skip</Button> }
            <Button size="lg" onClick={() => this.handleUpload(true)}>Upload</Button>
          </Form.Group>
        </Form>
      }
        <div hidden={this.state.step !== 'ROI'}>
          <div className="tool-menu">
            <div>
              <p>Click on the canvas to draw points. Press U to Undo, press C to Clear the current action.</p>
            </div>
            <Alert variant="danger" show={this.state.showAlert}>
              ROI and traffic lights are not complete.
            </Alert>
            <div className="mt-2">
              <Button variant="outline-primary" active={this.state.button_active[0]} onClick={() => this.handleButtonClick(0)}>Draw ROI</Button>
              { this.state.ROW === false && <Button variant="outline-primary" active={this.state.button_active[1]} className="ml-2" onClick={() => this.handleButtonClick(1)}>Draw Traffic Light</Button> }
              <Button variant="outline-danger" className="ml-2" onClick={() => this.handleUndo()}>Undo</Button>
              <Button variant="outline-danger" className="ml-2" onClick={() => this.handleClear()}>Clear current</Button>
              { this.state.isEditing && <Button variant="dark" className="ml-2" onClick={() => this.handleSubmit(false)}>Skip</Button> }
              <Button variant="outline-success" className="ml-2" onClick={() => this.handleSubmit(true)}>Submit</Button>
            </div>
            <div className="mt-2">
              <Form inline>
                <Form.Row>
                {
                  this.state.zone_points.map((point, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Label className="mr-1">Point {index + 1}</Form.Label>
                        <Form.Control readOnly value={point.x} size='sm' type="text" className="mr-1 input-box"></Form.Control>
                        <Form.Control readOnly value={point.y} size='sm' type="text" className="mr-1 input-box"></Form.Control>
                      </React.Fragment>
                    );
                  })
                }
                </Form.Row>
              </Form>
              <Form inline>
                <Form.Row>
                {
                  this.state.traffic_lights.map((point, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Form.Label className="mr-1">Light {index + 1}</Form.Label>
                        <Form.Control readOnly value={point.x} size='sm' type="text" className="mr-1 input-box"></Form.Control>
                        <Form.Control readOnly value={point.y} size='sm' type="text" className="mr-1 input-box"></Form.Control>
                      </React.Fragment>
                    );
                  })
                }
                </Form.Row>
              </Form>
            </div>
          </div>
          <div id="canvas"></div>
        </div>
      </div>
    );
  }
}

export default withRouter(LocationInfoPage);