import "../CSS/InfoContainer.css";

import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { EventSourcePolyfill } from "event-source-polyfill";
import Plot from "react-plotly.js";
import moment from "moment";
import { Form } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";

import InfoTable from "./InfoTable";

const REFRESH_INTERVAL = 1000;
const CHARTS_REFRESH_INTERVAL = 1000 * 60;
const LOCATION_URL = "/api/location";
const TRAFFIC_URL = "/api/traffic";
const TRESPASSING_URL = "/api/trespassing";
const SIGNAL_URL = "/api/signal";
const TRAINEVENT_URL = "/api/trainevent";
const SERVER_EVENTS_URL = "/api/events";

class InfoContainer extends React.Component {
  constructor(props) {
    super(props);
    // Non-state variables
    this.timer = null;
    this.eventSource = null;
    this.allTraffic = [];
    this.allTrespassing = [];
    // Set axios header
    this.axiosConfig = {
      headers: {
        Authorization: ["Bearer", props.token].join(" "),
      },
    };
    // State
    this.state = {
      selectedTab: null,
      location: null,
      locations: [],
      // TODO: Later for filtering data for processed video on front end
      // filteredTraffic: [],
      // filteredTrespassing: [],
      // filteredSignal: [],
      // filteredTrainEvent: [],
      trafficLineChartData: [],
      trespassingLineChartData: [],
      plotLayout: {
        autosize: true,
        margin: {
          l: 20,
          r: 20,
          t: 20,
          b: 20,
        },
      },
      traffic: [],
      trespassing: [],
      signal: [],
      trainEvent: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentWillUnmount() {
    // Clear the timer
    clearTimeout(this.timer);
    // Clear the event source
    if (this.eventSource) this.eventSource.close();
  }

  changeTab(tabName) {
    this.setState({
      selectedTab: tabName,
    });
  }

  handleLocationChange(e) {
    const id = e.target.value;
    this.props.history.push(`/location/${id}`);
  }

  changeTimelineForCompleteVideo() {
    if (
      this.props.playerContainer &&
      this.props.playerContainer.current.player
    ) {
      var currentTime =
        this.props.playerContainer.current.player.video.currentTime;
      // Change vertical time line
      this.setState({
        plotLayout: {
          ...this.state.plotLayout,
          shapes: [
            {
              type: "line",
              xref: "x",
              yref: "y",
              x0: moment().startOf("day").seconds(currentTime).toDate(),
              y0: 0,
              x1: moment().startOf("day").seconds(currentTime).toDate(),
              y1: 10e5,
            },
          ],
        },
      });
    }

    this.timer = setTimeout(this.changeTimelineForCompleteVideo.bind(this), 1000);
  }

  async setup() {
    // Get all locations
    try {
      var locations = await axios.get(`${LOCATION_URL}/all`, this.axiosConfig);
    } catch (e) {
      // If somehow cannot get info, redirect to locations page
      this.props.history.push(`/locations`);
    }

    locations = locations.data;
    let location = locations.find((l) => l._id === this.props.location_id);
    this.setState({ location: location, locations: locations });
    if (!this.state.selectedTab)
      this.setState({
        selectedTab: location.ROW === true ? "trespassing" : "traffic",
      }); // Initially set default tab, this only happen once

    var layout = {
      autosize: true,
      margin: {
        l: 50,
        r: 20,
        t: 20,
        b: 20,
      },
      yaxis: {
        tickformat: ",d",
        rangemode: "nonnegative",
      },
      showlegend: true,
      legend: {
        x: 1,
        xanchor: "right",
        y: 1,
      },
    };
    if (location.feed.type === "live") {
      layout = {
        ...layout,
        xaxis: {
          type: "date",
          tickformat: "%H",
        },
      };
      this.setState({ plotLayout: layout });
      // Location is live
      if (location.status === "stopped") {
        // try to set up again
        setTimeout(() => {
          this.setup();
        }, REFRESH_INTERVAL);
      } else {
        // Request data
        this.loadInitialData();
        // Set timer for refresh
        this.timer = setTimeout(this.refreshChartData.bind(this), CHARTS_REFRESH_INTERVAL);
      }
    } else if (location.feed.type === "video") {
      // Location is video
      if (location.status === "stopped") {
        // Set timer for location
        this.timer = setTimeout(() => {
          this.setup();
        }, REFRESH_INTERVAL);
      } else if (location.status === "processing") {
        // Set up event source
        this.setupEventSource(location._id); // If location is complete, do not set up event source
        // Location is still processing
        // Request data
        this.loadInitialData();
        // Set timer for refresh
        this.timer = setTimeout(this.refreshChartData.bind(this), CHARTS_REFRESH_INTERVAL);
      } else if (location.status === "complete") {
        layout = {
          ...layout,
          xaxis: {
            type: "date",
            tickformat: "%H:%M:%S",
          },
        };
        this.setState({ plotLayout: layout });
        // Load initial data
        this.loadInitialData();
        clearTimeout(this.timer);
        this.timer = setTimeout(this.changeTimelineForCompleteVideo.bind(this), 1000);
      }
    }
  }

  setupEventSource(location_id) {
    // Set up event source
    this.eventSource = new EventSourcePolyfill(
      `${SERVER_EVENTS_URL}/${location_id}`,
      {
        headers: {
          Authorization: ["Bearer", this.props.token].join(" "),
        },
      }
    );

    this.eventSource.onopen = () =>
      console.log("Event source starts listening");

    this.eventSource.onerror = (e) => console.error(e);

    this.eventSource.addEventListener("trafficTopic-dashboard", (event) => {
      let parsedData = JSON.parse(event.data);
      if (this.props.location_id === parsedData.locationId) {
        this.setState({
          traffic: [parsedData.message, ...this.state.traffic],
        });
        if (this.state.selectedTab === "traffic") this.flashNewRow();
      }
    });

    this.eventSource.addEventListener("trespassingTopic-dashboard", (event) => {
      let parsedData = JSON.parse(event.data);
      parsedData.message = JSON.parse(parsedData.message);
      if (this.props.location_id === parsedData.locationId) {
        let orderedSignals = [...this.state.signal].reverse();
        let nearMiss = this.getNearMissForTrespassing(
          parsedData.message,
          orderedSignals,
          this.state.location.feed.type
        );
        parsedData.message = {
          ...parsedData.message,
          timeBefore: nearMiss.timeBefore,
          timeAfter: nearMiss.timeAfter,
        };
        this.setState({
          trespassing: [parsedData.message, ...this.state.trespassing],
        });
        if (this.state.selectedTab === "trespassing") this.flashNewRow();
      }
    });

    this.eventSource.addEventListener("signalTopic-dashboard", (event) => {
      let parsedData = JSON.parse(event.data);
      if (this.state.location.feed.type === "live") {
        parsedData.message.duration =
          (new Date(parsedData.message.end_time) -
            new Date(parsedData.message.start_time)) /
          1000;
      } else if (this.state.location.feed.type === "video") {
        parsedData.message.duration =
          parsedData.message.end_time - parsedData.message.start_time;
      }
      if (this.props.location_id === parsedData.locationId) {
        this.setState({
          signal: [parsedData.message, ...this.state.signal],
        });
        if (this.state.selectedTab === "signal") this.flashNewRow();
      }
    });

    this.eventSource.addEventListener("trainEventTopic-dashboard", (event) => {
      let parsedData = JSON.parse(event.data);
      if (this.state.location.feed.type === "live") {
        parsedData.message.duration =
          (new Date(parsedData.message.end_time) -
            new Date(parsedData.message.start_time)) /
          1000;
      } else if (this.state.location.feed.type === "video") {
        parsedData.message.duration =
          parsedData.message.end_time - parsedData.message.start_time;
      }
      if (this.props.location_id === parsedData.locationId) {
        this.setState({
          trainEvent: [parsedData.message, ...this.state.trainEvent],
        });
        if (this.state.selectedTab === "train") this.flashNewRow();
      }
    });

    this.eventSource.addEventListener("locationTopic-dashboard", (event) => {
      const parsedData = JSON.parse(event.data);
      const message = parsedData.message;
      if (this.state.location) {
        let location = {
          ...this.state.location,
          status: message,
        };
        this.setState({ location: location });
      }
    });
  }

  flashNewRow() {
    const elements = document.getElementsByClassName("newrow");
    if (elements.length === 0) return;
    const element = elements[0];
    // This is a hack from CSS-tricks to restart the animation
    element.classList.remove("newrow");
    void element.offsetWidth;
    element.classList.add("newrow");
  }

  async loadInitialData(currentTime) {
    // Load initial data from back
    let all_traffic_request = axios.get(
      `${TRAFFIC_URL}/all/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let all_trespassing_request = axios.get(
      `${TRESPASSING_URL}/all/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let all_signal_request = axios.get(
      `${SIGNAL_URL}/all/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let all_trainevent_request = axios.get(
      `${TRAINEVENT_URL}/all/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let trafficLineChartData_request = axios.get(
      `${TRAFFIC_URL}/getLineChartData/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let trespassingLineChartData_request = axios.get(
      `${TRESPASSING_URL}/getLineChartData/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let results = await Promise.allSettled([
      all_traffic_request,
      all_trespassing_request,
      all_signal_request,
      all_trainevent_request,
      trafficLineChartData_request,
      trespassingLineChartData_request,
    ]);
    let newStates = {};
    if (results[0].status === "fulfilled")
      newStates.traffic = results[0].value.data;
    if (results[1].status === "fulfilled")
      newStates.trespassing = results[1].value.data;
    if (results[2].status === "fulfilled")
      newStates.signal = results[2].value.data;
    if (results[3].status === "fulfilled")
      newStates.trainEvent = results[3].value.data;
    if (results[4].status === "fulfilled")
      newStates.trafficLineChartData = formatDataForChart(
        results[4].value.data
      );
    if (results[5].status === "fulfilled")
      newStates.trespassingLineChartData = formatDataForChart(
        results[5].value.data
      );

    let orderedSignals;
    orderedSignals = [...newStates.signal].reverse();
    // Add near miss info to trespassing from signal
    newStates.trespassing = newStates.trespassing.map((trespassing) => {
      let nearMiss = this.getNearMissForTrespassing(
        trespassing,
        orderedSignals,
        this.state.location.feed.type
      );
      return {
        ...trespassing,
        timeBefore: nearMiss.timeBefore,
        timeAfter: nearMiss.timeAfter,
      };
    });

    this.setState(newStates);

    // Set up event source
    this.setupEventSource(this.state.location._id);

    this.setState({
      isLoading: false,
    });
  }

  getNearMissForTrespassing(trespassing, signals, feed_type) {
    if (!feed_type)
      return {
        timeBefore: null,
        timeAfter: null,
      };
    // Signals should be in ascending order in start_time
    let precedingEvent = null;
    let succeedingEvent = null;
    let start_time =
      feed_type === "video"
        ? trespassing.start_time
        : new Date(trespassing.start_time);
    for (let i = 0; i < signals.length; i++) {
      let signal_start_time =
        feed_type === "video"
          ? signals[i].start_time
          : new Date(signals[i].start_time);
      if (signal_start_time <= start_time) {
        precedingEvent = signals[i];
        succeedingEvent = i + 1 < signals.length ? signals[i + 1] : null;
      }
    }
    return {
      timeBefore: precedingEvent
        ? feed_type === "video"
          ? trespassing.start_time - precedingEvent.start_time
          : (new Date(trespassing.start_time) -
              new Date(precedingEvent.start_time)) /
            1000
        : null,
      timeAfter: succeedingEvent
        ? feed_type === "video"
          ? succeedingEvent.start_time - trespassing.start_time
          : (new Date(succeedingEvent.start_time) -
              new Date(trespassing.start_time)) /
            1000
        : null,
    };
  }

  async refreshChartData(currentTime) {
    // Refresh data, if currentTime is supplied, add that to request path
    let trafficLineChartData_request = axios.get(
      `${TRAFFIC_URL}/getLineChartData/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let trespassingLineChartData_request = axios.get(
      `${TRESPASSING_URL}/getLineChartData/${this.props.location_id}/${
        currentTime !== undefined ? currentTime : ""
      }`,
      this.axiosConfig
    );
    let results = await Promise.allSettled([
      trafficLineChartData_request,
      trespassingLineChartData_request,
    ]);
    let newStates = {};
    if (results[0].status === "fulfilled")
      newStates.trafficLineChartData = formatDataForChart(
        results[0].value.data
      );
    if (results[1].status === "fulfilled")
      newStates.trespassingLineChartData = formatDataForChart(
        results[1].value.data
      );
    this.setState(newStates);
  }

  render() {
    return (
      <div className="info-container">
        <div className="header-container">
          <Form>
            <Form.Group>
              <Form.Label>
                <Badge variant="dark" style={{ fontSize: "1rem" }}>
                  Location
                </Badge>{" "}
                -{" "}
                <Badge variant="primary" style={{ fontSize: "1rem" }}>
                  {this.state.location ? this.state.location.status : ""}
                </Badge>
              </Form.Label>
              <Form.Control
                as="select"
                value={this.props.location_id}
                onChange={(e) => {
                  this.handleLocationChange(e);
                }}
              >
                {this.state.locations.map((location, index) => {
                  return (
                    <option value={location._id} key={index}>
                      {location.name} - {location.city}, {location.state}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
          <ButtonGroup aria-label="Tab" style={{ width: "100%" }}>
            {!this.state.location?.ROW && (
              <Button
                variant="light"
                active={this.state.selectedTab === "traffic"}
                onClick={() => this.changeTab("traffic")}
              >
                Traffic
              </Button>
            )}
            <Button
              variant="light"
              active={this.state.selectedTab === "trespassing"}
              onClick={() => this.changeTab("trespassing")}
            >
              Trespassing
            </Button>
            {!this.state.location?.ROW && (
              <Button
                variant="light"
                active={this.state.selectedTab === "signal"}
                onClick={() => this.changeTab("signal")}
              >
                Signal
              </Button>
            )}
            <Button
              variant="light"
              active={this.state.selectedTab === "train"}
              onClick={() => this.changeTab("train")}
            >
              Train
            </Button>
            <Button
              variant="success"
              active={false}
              href={`/api/location/download/${this.props.location_id}?token=${this.props.token}`}
              title="Download all data in a zip file"
            >
              Download{" "}
              {this.state.location
                ? this.state.location.feed.type === "live"
                  ? "Today"
                  : "All"
                : null}
            </Button>
          </ButtonGroup>
        </div>

        <div className="table-container">
          {!this.state.isLoading && (
            <InfoTable
              sourceType={
                this.state.location !== null
                  ? this.state.location.feed.type
                  : null
              }
              selectedTabName={this.state.selectedTab}
              data={
                this.state.selectedTab === "traffic"
                  ? this.state.traffic
                  : this.state.selectedTab === "trespassing"
                  ? this.state.trespassing
                  : this.state.selectedTab === "signal"
                  ? this.state.signal
                  : this.state.selectedTab === "train"
                  ? this.state.trainEvent
                  : []
              }
              token={this.props.token}
            />
          )}
          {this.state.isLoading && (
            <div className="spinner-center-helper">
              <Spinner animation="border" role="status" className="spinner"></Spinner>
            </div>
          )}
        </div>

        <div
          className="chart-container"
          hidden={
            this.state.selectedTab !== "traffic" &&
            this.state.selectedTab !== "trespassing"
          }
        >
          <div hidden={this.state.selectedTab !== "traffic"}>
            <Plot
              style={{ width: "100%" }}
              data={this.state.trafficLineChartData}
              layout={this.state.plotLayout}
              useResizeHandler={true}
            />
          </div>
          <div hidden={this.state.selectedTab !== "trespassing"}>
            <Plot
              style={{ width: "100%" }}
              data={this.state.trespassingLineChartData}
              layout={this.state.plotLayout}
              useResizeHandler={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

function formatDataForChart(data) {
  let chartData = [];

  // Get x axis coordinates ready
  let x = data.shift();
  x.shift();
  x = x.map((seconds) => moment().startOf("day").seconds(seconds).toDate()); // Convert seconds to Date objects

  // Get other traces ready
  data.forEach((d) => {
    let traceName = d.shift();
    chartData.push({
      x: x,
      y: d,
      name: traceName,
      mode: "lines+markers",
    });
  });

  return chartData;
}

export default withRouter(InfoContainer);
