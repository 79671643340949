import React, { useRef, useMemo, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";

import "../CSS/LocationPage.css";
import { Container, Row, Col } from "react-bootstrap";
import NavigationBar from "./Navbar";
import VideoContainer from "./VideoContainer";
import InfoContainer from "./InfoContainer";

function LocationPage({ setToken }) {
  const token = localStorage.getItem("token");
  const [userInfo, setUserInfo] = useState({});

  let history = useHistory();
  let location_id = useRouteMatch("/location/:id").params.id;
  const playerContainerRef = useRef(null);

  // Set axios header
  const axiosConfig = useMemo(() => {
    return {
      headers: {
        Authorization: ["Bearer", token].join(" "),
      },
    };
  }, [token]);

  const fetchUserInfo = () => {
    if (token) {
      axios
        .get("/api/user/auth", axiosConfig)
        .then((res) => {
          setUserInfo(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Unauthorized
            console.error(err);
            localStorage.removeItem("token");
          }
        });
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, [token]);

  // Redirect if logged out
  if (!token) {
    history.push("/");
    return null;
  }

  return (
    <div className="single-page">
      <NavigationBar userInfo={userInfo} setToken={setToken} />
      <Container fluid className="h-100 p-0">
        <Row
          noGutters={true}
          className="h-100"
          lg={12}
        >
          <Col
            lg={6}
          >
            <VideoContainer
              fullpage={userInfo.role === "viewer" ? true : false}
              token={token}
              location_id={location_id}
              ref={playerContainerRef}
            />
          </Col>
          <Col
            lg={6}
          >
            {userInfo.role && userInfo.role !== "viewer" && (
              <InfoContainer
                token={token}
                key={location_id}
                location_id={location_id}
                playerContainer={playerContainerRef}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LocationPage;
